<template>
  <div style="width: 100%;" @click="handleClear">
    <page-header
      style="padding: 20px 30px; width: calc(100% - 335px)"
      class="absolute"
      :title="__('SMS Profiles')"
    />
    <el-button
      :disabled="!can('manage.sms-profiles.write')"
      class="createBtn"
      @click.stop="createContentItem"
      >{{ __("Create SMS Profile") }}</el-button
    >
    <div style="padding: 0 36px; margin-top: 136px;">
      <el-form class="form-container">
        <PaginationToolbar
          :sort-by-options="sortByOptions"
          :content-api="contentAPI"
          @success="$emit('replaceContent', $event)"
        >
        </PaginationToolbar>
      </el-form>
      <el-scrollbar :native="true">
        <div class="contentListContainer">
          <el-table
            ref="smsProfilesTable"
            class="list-table"
            :data="smsProfiles"
            highlight-current-row
            @row-click="handleSingleClick"
            @row-dblclick="handleDoubleClick"
            v-loading="loading"
            v-show="can('manage.sms-profiles.read')"
          >
            <el-table-column :label="__('ID')">
              <template slot-scope="scope">
                <span>{{ scope.row.sms_profile_id }}</span>
              </template>
            </el-table-column>

            <el-table-column :label="__('SMS profile')">
              <template slot-scope="scope">
                <span>{{ scope.row.sms_profile_name }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PageHeader from "@/components/PageHeader";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";

export default {
  name: "SMSProfilesManageList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar,
    PageHeader
  },
  data() {
    return {
      sortByColumns: ["sms_profile_name", "sms_profile_id"]
    };
  },

  computed: {
    ...mapState("smsprofiles", {
      smsProfiles: state => state.smsProfiles,
      loading: state => state.loading
    })
  },

  methods: {
    ...mapActions("smsprofiles", {
      contentAPI: "getSmsProfiles",
      deleteContentMethod: "deleteSmsProfile",
      undoDeleteContent: "undoDeleteSmsProfile"
    }),

    handleClear() {
      this.$refs.smsProfilesTable &&
        this.$refs.smsProfilesTable.setCurrentRow();
      this.handleClearSelection();
    },

    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },

    handleDoubleClick(row, column, event) {
      event.stopPropagation();
      // if (this.can("manage.sms-profiles.write")) {
      this.handleEdit(null, row);
      // }
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";
</style>
